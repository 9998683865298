import { combineReducers } from "redux";

import authReducer from "./auth";
import menuReducer from "./nav";
import agencyReducer from "./agency";
import carsReducer from "./cars";
import inboxReducer from "./inbox";
import shortcutReducer from "./shortcut";
import reservationreducer from "./reservation";
import templateReducer from "./template";

const allReducers = combineReducers({
  authreducer: authReducer,
  menu: menuReducer,
  agencyreducer: agencyReducer,
  carsreducer: carsReducer,
  inboxreducer: inboxReducer,
  shortcutreducer: shortcutReducer,
  reservationreducer: reservationreducer,
  templatereducer: templateReducer,
});

export default allReducers;
