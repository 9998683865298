import { RESERVATION } from "../constants/actions";

const INIT_STATE = [];

export default (state = INIT_STATE, action) => {
  switch (action?.type) {
    case RESERVATION:
      return action.payload ?? null;
    default:
      return state;
  }
};
