import {
  CARS,
} from '../constants/actions';

const INIT_STATE = [];

export default (state = INIT_STATE, action) => {
  switch (action.type) {
      case CARS:
          return action.payload;
      default: 
        return state;
  }
}
