import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";

// Style
import "./assets/css/vendor/bootstrap.min.css";
import "./assets/css/vendor/bootstrap.rtl.only.min.css";
import "react-circular-progressbar/dist/styles.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-table-v6/react-table.css";
import "react-image-lightbox/style.css";
import "video.js/dist/video-js.css";
import "./assets/css/sass/themes/gogo.light.green.scss";
import "./assets/css/app.css";

import * as serviceWorker from "./serviceWorker";

import allReducers from "./reducers";

const App = React.lazy(() => import(/* webpackChunkName: "App" */ "./app"));

const store = createStore(allReducers);

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<div className="loading" />}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
