import {
  MENU_HIDDEN,
  MENU_EXPOSED
} from '../constants/actions';

const INIT_STATE = {
  menuclass: MENU_EXPOSED
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
      case MENU_EXPOSED:
        return { menuclass: MENU_EXPOSED, error: '' };
      case MENU_HIDDEN:
        return { menuclass: MENU_HIDDEN, error: '' };
      default: return { ...state };
  }
}
