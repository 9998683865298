import {
  SHORTCUTS
} from '../constants/actions';

const INIT_STATE = [];

export default (state = INIT_STATE, action) => {
  switch (action.type) {
      case SHORTCUTS:
          return action.payload;
      default: 
        return state;
  }
}
